import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import AlreadyHaveAccountBanner from "./alreadyHaveAccountBanner"
import AuthService from "../../services/auth"
import CONFIG from "../../config"
import PasswordInput from "../../components/common/forms/password"
import { Button, Input, Spinner } from "reactstrap"
import { Role } from "../../interfaces/role"
import { classroomSelector, ClassroomState, fetchGroupAction } from "../../hasura/slices/classroom"
import { commaSeparatedString, parseIdFrom, searchQueryParams } from "../../lib/helpers"

import {
  setAccessTokenAction,
  setAuthorizationErrorMessageAction,
  updateUserCurriculumIdAction,
  updateUserGroupIdAction,
  updateUserIsIndividualAction,
  userSelector,
  UserState,
} from "../../hasura/slices/user"

// @ts-ignore
import googleIcon from "../../lib/images/google.svg"

interface Props {
  role: Role
  isStudent: boolean
  isIndividual: boolean
}

const authService = new AuthService()

export default function NewAccount(props: Props) {
  const dispatch = useDispatch()

  const [nameOrEmail, setNameOrEmail] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [isSigningUp, setIsSigningUp] = useState(false)

  const { classroomForJoinCode, group }: ClassroomState = useSelector(classroomSelector)
  const { authorizationErrorMessage }: UserState = useSelector(userSelector)

  const maxClassSizeReached = (classroomForJoinCode?.students_aggregate.aggregate?.count || 0) >= CONFIG.MAX_CLASS_SIZE

  const curriculumId = parseInt(searchQueryParams("c") || "", 10)

  useEffect(() => {
    dispatch(setAuthorizationErrorMessageAction(undefined))
  }, [])

  useEffect(() => {
    const groupId = parseInt(searchQueryParams("g") || "", 10)
    if (!groupId) return

    dispatch(fetchGroupAction(groupId))
  }, [])

  const signUpCallback = async (accessToken?: string, error?: string) => {
    if (accessToken) {
      const id = parseIdFrom(accessToken)
      if (props.isIndividual && id) {
        await dispatch(updateUserIsIndividualAction(accessToken, id, true))
      }
      if (props.role === Role.Teacher && group && id) {
        await dispatch(updateUserGroupIdAction(accessToken, id, group.id))
      }
      if (curriculumId && id) {
        await dispatch(updateUserCurriculumIdAction(accessToken, id, curriculumId))
      }
      dispatch(setAccessTokenAction(accessToken))
      navigate("/home")
    } else if (error) {
      setIsSigningUp(false)
      dispatch(setAuthorizationErrorMessageAction(error))
    }
  }

  const signUp = () => {
    if (props.isStudent && nameOrEmail?.includes(" ")) {
      return dispatch(setAuthorizationErrorMessageAction("Please remove spaces from your username."))
    }

    setIsSigningUp(true)
    const email = props.isStudent ? authService.getEmailFor(nameOrEmail!) : nameOrEmail!
    authService.signUp(
      email,
      password!,
      props.role,
      props.isIndividual,
      classroomForJoinCode && props.isStudent ? String(classroomForJoinCode.id) : null,
      signUpCallback
    )
  }

  const googleSignUp = () => {
    setIsSigningUp(true)
    authService.google(props.isIndividual ? "individual" : props.role, classroomForJoinCode?.id, signUpCallback)
  }

  const isJoiningClass = classroomForJoinCode && props.isStudent

  return (
    <div className="text-center pt-4">
      <h1 className="bold text-xxl text-primary text-capitalize">
        {group ? `Join ${group.display_name} on Wordcraft` : props.isIndividual ? "Sign-Up" : `${props.role} Sign-Up`}
      </h1>

      {group && (
        <div>
          <p className="text-m text--gray7 mt-2 mb-0">Wordcraft is a learning platform that kids love.</p>

          <p className="text-s text--gray6 mt-1">
            {commaSeparatedString(group.users.map((u) => u.display_name))} {group.users.length > 1 ? "have" : "has"} already joined
          </p>
        </div>
      )}

      {isJoiningClass && !maxClassSizeReached && (
        <p className="m-0">
          Ready to join {classroomForJoinCode?.teacher.display_name}'s <span className="bold">{classroomForJoinCode?.display_name}</span>?
        </p>
      )}

      {isJoiningClass && maxClassSizeReached && (
        <p className="text--danger m-0 max-width-300px mx-auto">
          Unable to join {classroomForJoinCode?.teacher.display_name}'s <span className="bold">{classroomForJoinCode?.display_name}</span> because it
          is already full.
        </p>
      )}

      {isSigningUp ? (
        <Spinner className="my-2" size="lg" color="primary" />
      ) : (
        <div style={{ maxWidth: "300px", margin: "0 auto" }} className="p-3 my-3">
          <Button
            className="w-100 button-no-hover d-flex align-items-center justify-content-center"
            color="primary"
            onClick={googleSignUp}
            disabled={maxClassSizeReached}
            outline
            size="lg"
          >
            <img className="icon-s mr-2" src={googleIcon} />
            Sign up with Google
          </Button>

          <p className="my-2 text-muted text-m">or</p>

          <Input
            autoFocus
            bsSize="lg"
            className="my-2 data-hj-allow"
            onChange={(e) => setNameOrEmail(e.target.value)}
            placeholder={props.isStudent ? "email or username" : "email"}
            value={nameOrEmail || ""}
            autoCapitalize="none"
          />

          <PasswordInput password={password} setPassword={setPassword} />

          <Button color="primary" disabled={!nameOrEmail || !password || maxClassSizeReached} onClick={signUp} className="mb-3 w-100" size="lg">
            Sign up{props.isIndividual ? "" : ` for a ${props.role} account`}
          </Button>

          <p className="text-muted text-xs">
            By signing up, you agree to our <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.
          </p>

          {authorizationErrorMessage && <p className="error">{authorizationErrorMessage}</p>}
        </div>
      )}

      <AlreadyHaveAccountBanner />
    </div>
  )
}
