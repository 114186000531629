import React from "react"
import { navigate } from "gatsby"
import { Button } from "reactstrap"

export default function AlreadyHaveAccountBanner() {
  return (
    <div style={{ top: "85vh" }} className="position-fixed bg--gray2 d-flex flex-center 100-vw l-0 r-0 py-3 hide-mobile">
      <p className="m-0 text--gray7 mr-3 text-l">Already have a Wordcraft account? </p>

      <Button onClick={() => navigate("/?l=t")} size="lg" className="min-width-100px shadow" color="primary">
        Log In
      </Button>
    </div>
  )
}
