import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import NewAccount from "./newAccount"
import { Role } from "../../interfaces/role"

export default function SignUpIndividual() {
  return (
    <Layout>
      <SEO title="Individual Sign Up" />

      <NewAccount isStudent={true} isIndividual={true} role={Role.Student} />
    </Layout>
  )
}
