import React, { useState } from "react"
import { Input } from "reactstrap"

interface Props {
  password?: string
  setPassword: (value: string) => void
}

export default (props: Props) => {
  const [showPassword, setShowPassword] = useState(true)

  return (
    <div className="position-relative">
      <Input
        autoCapitalize="none"
        bsSize="lg"
        className="my-2"
        onChange={(e: any) => props.setPassword(e.target.value)}
        placeholder="password"
        spellCheck={false}
        type={showPassword ? "text" : "password"}
        value={props.password || ""}
      />

      <p
        role="button"
        style={{ right: "10px", top: "50%", transform: "translateY(-50%)" }}
        className={`position-absolute m-0 text-s bold text-primary transition-m`}
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? "HIDE" : "SHOW"}
      </p>
    </div>
  )
}
